import React, { useState, useEffect } from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout/Layout"
import PageTransitionContainer from "../components/PageTransitionContainer"
import "../styles/pages/book-appointment.css"
import { InlineWidget } from "react-calendly"

const BookAppointment = (props) => {
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false)
    }, 1000)
  }, [showLoading])

  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="getstarted" breakout={true}>
        <Seo
          title="Book Appointment |
            RateLock"
          location={props.location}
        />
        <section id="book-appointment">
          <div className="content">
            {/* <h1 className="page-heading">Book An Appointment</h1> */}
            <InlineWidget
              styles={{
                height: "660px",
                minWidth: "320px"
              }}
              url="https://calendly.com/rajvsharma"
            />
          </div>
        </section>
      </Layout>
    </PageTransitionContainer>
  )
}

export default BookAppointment
